<template>
  <div>
    <treeContainer asideWidth="150px">
      <template v-slot:tree>
        <el-input
          class="u-bottom-margin--2x"
          :placeholder="$t('sectionsPageSectionFilterLabel')"
          v-model="sectionFilter"
        >
        </el-input>

        <el-button
          type="primary"
          size="mini"
          @click="addSectionDialog = true"
          >{{ $t("sectionsPageAddSectionButtonLabel") }}</el-button
        >
        <el-tree
          :data="lookups.sections"
          class="u-top-margin--2x"
          node-key="id"
          @node-click="sectionClicked"
          :props="sectiondefaultProps"
          :expand-on-click-node="false"
          :filter-node-method="filterSectionNode"
          ref="sectionTree"
        >
          <span
            class="custom-tree-node"
            :class="{ selected: data.key == selectedSection.key }"
            slot-scope="{ node, data }"
          >
            {{ node.label }}

            <div>
              <el-button
                class="u-font--bold"
                type="text"
                size="mini"
                @click.stop="openEditDialog(data)"
                >{{ $t("sectionsPageSectionEdit") }}</el-button
              >
            </div>
          </span>
        </el-tree>
      </template>
      <template v-slot:tree-page>
        <treeContainer asideWidth="150px">
          <template v-slot:tree>
            <el-input
              class="u-bottom-margin--2x"
              :placeholder="$t('sectionsPageRegionFilterLabel')"
              v-model="filterText"
            >
            </el-input>

            <el-tree
              :data="regionsList"
              class="u-top-margin--2x"
              node-key="id"
              @node-click="reigonClicked"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
            >
              <span
                class="custom-tree-node"
                :class="{ selected: data.id == selectedRegion.id }"
                slot-scope="{ node, data }"
              >
                {{ node.label }}
                <div></div>
              </span>
            </el-tree>
          </template>

          <template v-slot:tree-page>
            <template v-if="regionSelected && sectionSelected">
              <div class="u-font--super">
                {{ $t("sectionsPageTableSectionLabel") }}:
                {{ selectedSection.title }}
              </div>

              <div class="u-font--large">
                {{ $t("sectionsPageTableRegionLabel") }}:
                {{ selectedRegion.name }}
              </div>

              <div>
                <!-- <el-input
                  placeholder="Filter Provders"
                  v-model="providerFilter.name" 
                  @change="filterChanged"
                >
                </el-input> -->
                <el-button
                  v-if="providerChanged"
                  class="sections-page__float-button"
                  type="primary"
                  size="default"
                  @click="assginProviderToSection"
                  >{{ $t("sectionsPageSaveButtonLabel") }}</el-button
                >
              </div>

              <el-table
                class="u-top-margin--5x"
                :data="providersList"
                border
                stripe
              >
                <el-table-column
                  prop="id"
                  :label="$t('sectionsPageTableColumnId')"
                  width="50px"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="$t('sectionsPageTableColumnName')"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="providerType"
                  :label="$t('sectionsPageTableColumnProviderType')"
                >
                </el-table-column>
                <el-table-column
                  prop="minNumberItems"
                  :label="$t('sectionsPageTableColumnMinNumberItems')"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.minNumberItems"
                      @change="scope.row.updated = true"
                      placeholder=""
                      size="mini"
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="minOrderAmount"
                  :label="$t('sectionsPageTableColumnMinOrderAmount')"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.minOrderAmount"
                      @change="scope.row.updated = true"
                      placeholder=""
                      size="mini"
                      clearable
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="rating"
                  :label="$t('sectionsPageTableColumnRating')"
                >
                  <template slot-scope="scope">
                    <el-rate
                      v-model="scope.row.rating"
                      @change="scope.row.updated = true"
                      :allow-half="true"
                    ></el-rate>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="provider in section">
                  <template slot-scope="scope">
                    <div>
                      <el-switch
                        v-model="scope.row.checked"
                        @change="scope.row.updated = true"
                      >
                      </el-switch></div
                  ></template>
                </el-table-column> -->
              </el-table>
            </template>
            <template v-else>
              <div class="msg-page u-font--super">
                {{ $t("sectionsPageTableNoDataSelectedMessage") }}
              </div>
            </template>
          </template>
        </treeContainer>
      </template>
    </treeContainer>
    <el-dialog
      :title="$t('sectionsPageCreateDialogLabel')"
      :visible.sync="addSectionDialog"
    >
      <el-form :rules="rules" ref="addSectionForm" :model="addSection">
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelKey')"
          prop="key"
          label-width="150px"
        >
          <el-input
            size="mini"
            v-model="addSection.key"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelTitle')"
          prop="title"
          label-width="150px"
        >
          <el-input
            size="mini"
            v-model="addSection.title"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelImageURL')"
          prop="image"
          label-width="150px"
        >
          <el-input
            size="mini"
            v-model="addSection.image"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelSellingType')"
              prop="selling_type"
              label-width="150px"
            >
              <el-select v-model="addSection.selling_type" size="mini">
                <el-option
                  v-for="item in sellingTypesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <!--<el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelProviderType')"
              prop="provider_type"
              label-width="150px"
            >
              <el-select v-model="addSection.provider_type" size="mini">
                <el-option
                  v-for="item in providerTypesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item> 
          </el-col>-->
          <el-col
            :span="12"
            :offset="0"
            v-if="
              providesActivityTypeArray && providesActivityTypeArray.length > 0
            "
          >
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelEntityType')"
              prop="entityTypeId"
              label-width="150px"
            >
              <el-select v-model="addSection.entityTypeId" size="mini">
                <el-option
                  v-for="item in providesActivityTypeArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelCreditType')"
              prop="credit_enabled"
              label-width="150px"
            >
              <el-select v-model="addSection.credit_enabled" size="mini">
                <el-option
                  v-for="item in creditOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" :offset="0" v-if="lookups && lookups.entity_types">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelEntityType')"
              prop="entityTypeId"
              label-width="150px"
            >
              <el-select v-model="addSection.entityTypeId" size="mini">
                <el-option
                  v-for="item in lookups.entity_types"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelInfo')"
          label-width="150px"
        >
          <el-input
            v-model="addSection.meta.info"
            size="mini"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelSubtitle')"
          label-width="150px"
        >
          <el-input
            v-model="addSection.meta.subtitle"
            size="mini"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelFullWidth')"
          label-width="150px"
        >
          <el-switch
            v-model="addSection.meta.full_width"
            :active-value="true"
            :inactive-value="false"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelBarAvailability')"
          label-width="150px"
        >
          <el-switch v-model="addSection.meta.searchBar.pin"></el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelPlaceholderText')"
          label-width="150px"
        >
          <el-input
            v-model="addSection.meta.searchBar.text"
            autocomplete="off"
            :disabled="addSection.meta.searchBar.pin === false"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addSectionDialog = false">{{
          $t("sectionsPageCancelButtonLabel")
        }}</el-button>
        <el-button type="primary" @click="createSection">{{
          $t("sectionsPageAddButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('sectionsPageEditDialogLabel')"
      :visible.sync="editSectionDialog"
    >
      <el-form :rules="rules" ref="editSectionForm" :model="editSection">
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelKey')"
          prop="key"
          label-width="150px"
        >
          <el-input
            size="mini"
            disabled="true"
            v-model="editSection.key"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelTitle')"
          prop="title"
          label-width="150px"
        >
          <el-input
            size="mini"
            :disabled="!editSectionMode"
            v-model="editSection.title"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelImageURL')"
          prop="image"
          label-width="150px"
        >
          <el-input
            size="mini"
            :disabled="!editSectionMode"
            v-model="editSection.image"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelSellingType')"
              prop="selling_type"
              label-width="150px"
            >
              <el-select
                :disabled="!editSectionMode"
                v-model="editSection.selling_type"
                size="mini"
              >
                <el-option
                  v-for="item in sellingTypesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <!--  <el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelProviderType')"
              prop="provider_type"
              label-width="150px"
            >
              <el-select
                :disabled="!editSectionMode"
                v-model="editSection.provider_type"
                size="mini"
              >
                <el-option
                  v-for="item in providerTypesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col
            :span="12"
            :offset="0"
            v-if="
              providesActivityTypeArray && providesActivityTypeArray.length > 0
            "
          >
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelEntityType')"
              prop="entityTypeId"
              label-width="150px"
            >
              <el-select
                :disabled="!editSectionMode"
                v-model="editSection.entityTypeId"
                size="mini"
              >
                <el-option
                  v-for="item in providesActivityTypeArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" :offset="0">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelCreditType')"
              prop="credit_enabled"
              label-width="150px"
            >
              <el-select
                :disabled="!editSectionMode"
                v-model="editSection.credit_enabled"
                size="mini"
              >
                <el-option
                  v-for="item in creditOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" :offset="0" v-if="lookups && lookups.entity_types">
            <el-form-item
              :label="$t('sectionsPageDialogFieldLabelEntityType')"
              prop="entityTypeId"
              label-width="150px"
            >
              <el-select 
                :disabled="!editSectionMode" 
                v-model="editSection.entityTypeId" 
                size="mini">
                <el-option
                  v-for="item in lookups.entity_types"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
        </el-row>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelInfo')"
          label-width="150px"
        >
          <el-input
            v-model="editSection.meta.info"
            :disabled="!editSectionMode"
            size="mini"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelSubtitle')"
          label-width="150px"
        >
          <el-input
            v-model="editSection.meta.subtitle"
            size="mini"
            :disabled="!editSectionMode"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelFullWidth')"
          label-width="150px"
        >
          <el-switch
            v-model="editSection.meta.full_width"
            :active-value="true"
            :disabled="!editSectionMode"
            :inactive-value="false"
          >
          </el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelBarAvailability')"
          label-width="150px"
        >
          <el-switch
            :disabled="!editSectionMode"
            v-model="editSection.meta.searchBar.pin"
          ></el-switch>
        </el-form-item>
        <el-form-item
          :label="$t('sectionsPageDialogFieldLabelPlaceholderText')"
          label-width="150px"
        >
          <el-input
            :disabled="!editSectionMode"
            v-model="editSection.meta.searchBar.text"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editSectionDialog = false">{{
          $t("sectionsPageCancelButtonLabel")
        }}</el-button>

        <el-button
          icon="el-icon-edit
"
          v-if="!editSectionMode"
          type="primary"
          @click="editSectionMode = true"
          >{{ $t("sectionsPageEditButtonLabel") }}</el-button
        >
        <el-button
          v-else
          icon="el-icon-close"
          type="danger"
          @click="cancelEdit"
          >{{ $t("sectionsPageEditButtonLabel") }}</el-button
        >

        <el-button v-if="editSectionMode" type="primary" @click="EditSection">{{
          $t("sectionsPageSaveButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import treeContainer from "@/Containers/treeContainer.vue";
import {
  regionsService,
  providersService,
  sectionsService,
} from "../../services";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
export default {
  components: { treeContainer },
  data() {
    return {
      addSectionDialog: false,
      editSectionDialog: false,
      editSectionMode: false,
      editSection: {
        meta: {
          searchBar: {},
        },
      },

      sectionOldKey: "",
      sellingTypesOptions: [
        {
          value: "direct",
          label: this.$t("sectionsPageDataSellingTypesOptionDirect"),
        },
        {
          value: "aggregate",
          label: this.$t("sectionsPageDataSellingTypesOptionAggregate"),
        },
      ],
      providerTypesOptions: [
        {
          value: "distributer",
          label: this.$t("sectionsPageDataProviderTypesOptionDistributer"),
        },
        {
          value: "supplier",
          label: this.$t("sectionsPageDataProviderTypesOptionSupplier"),
        },
      ],
      creditOptions: [
        {
          value: "credit",
          label: this.$t("sectionsPageDataCreditOptionsOptionCredit"),
        },
        {
          value: "cash",
          label: this.$t("sectionsPageDataCreditOptionsOptionCash"),
        },
        {
          value: "both",
          label: this.$t("sectionsPageDataCreditOptionsOptionBoth"),
        },
      ],
      addSection: {
        meta: {
          searchBar: {
            pin: true,
            text: this.$t("sectionsPageDataAddSectionDefaultSearchBarText"),
          },
        },
      },
      rules: {
        key: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleKey"),
            trigger: "blur",
          },
          {
            max: 15,
            message: this.$t("sectionsPageValidationRuleKeyError"),
            trigger: "change",
          },
        ],
        title: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleTitle"),
            trigger: "blur",
          },
        ],
        image: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleImageURL"),
            trigger: "blur",
          },
        ],
        selling_type: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleSellingType"),
            trigger: "blur",
          },
        ],
        provider_type: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleProviderType"),
            trigger: "blur",
          },
        ],
        credit_enabled: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleCredit"),
            trigger: "blur",
          },
        ],
        entityTypeId: [
          {
            required: true,
            message: this.$t("sectionsPageValidationRuleEntityType"),
            trigger: "blur",
          },
        ],
      },

      selectedRegion: {
        name: "",
        id: -1111,
      },
      selectedSection: {},
      regionsList: [],
      providersList: [],
      filterText: "",
      sectionFilter: "",
      defaultProps: {
        children: "childs",
        label: "name",
      },
      sectiondefaultProps: {
        label: "title",
      },
      providerFilter: {},
      providersData: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    sectionFilter(val) {
      this.$refs.sectionTree.filter(val);
    },
  },
  async created() {
    await this.fetchSectionslookup();
    await this.getRegions();
    // this.getAreas();
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
    regionSelected() {
      return this.selectedRegion.name;
    },
    sectionSelected() {
      return this.selectedSection.key;
    },
    providerChanged() {
      return (
        JSON.stringify(this.providersList) != JSON.stringify(this.providersData)
      );
    },
    providesActivityTypeArray() {
      let currentLookups =
        this.lookups && this.lookups.entity_types
          ? this.lookups.entity_types.filter(
              (singleType) =>
                singleType.activity && singleType.activity == "provides"
            )
          : [];
      return currentLookups;
    },
  },
  methods: {
    ...mapActions({
      fetchSectionslookup: "setSectionslookup",
    }),
    async openEditDialog(data) {
      this.$loading();
      try {
        let sectionResponse = await sectionsService.getSection(data.key);

        let temp = JSON.parse(JSON.stringify(sectionResponse.data.data[0]));
        if (temp.meta == null) {
          temp.meta = {
            searchBar: {
              pin: null,
              text: null,
            },
          };
        } else {
          if (!temp.meta.searchBar) {
            temp.meta.searchBar = {};
          }
        }
        this.editSection = temp;
        this.editSectionDialog = true;
        this.sectionOldKey = this.editSection.key;
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    async cancelEdit() {
      this.$loading();
      try {
        let sectionResponse = await sectionsService.getSection(
          this.sectionOldKey
        );

        this.editSection = sectionResponse.data.data[0];
        if (this.editSection.meta == null) this.editSection.meta = {};
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.$loading().close();
        this.editSectionMode = false;
      }
    },
    async EditSection() {
      this.$refs[`editSectionForm`].validate(async (valid) => {
        if (valid) {
          this.$loading();
          try {
            await sectionsService.updateSection(
              this.sectionOldKey,
              this.editSection
            );
            await sectionsService.getSection(this.editSection.key);
            await this.fetchSectionslookup();
            this.editSectionMode = false;
          } catch (err) {
            console.log("******************ERORR*********************");
            this.$notify({
              title: "Error ",
              message: err.response.data.error,
              type: "error",
            });
          } finally {
            this.$loading().close();
          }
        }
      });
    },

    async createSection() {
      this.$refs[`addSectionForm`].validate(async (valid) => {
        if (valid) {
          try {
            await sectionsService.createSection(this.addSection);
            await this.fetchSectionslookup();
            this.addSectionDialog = false;
          } catch (err) {
            console.log("******************ERORR*********************");
            this.$notify({
              title: "Error ",
              message: err.response.data.error,
              type: "error",
            });
          }
        }
      });
    },
    async assginProviderToSection() {
      const sectionKey = this.selectedSection.key;
      const Regionid = this.selectedRegion.id;
      this.$confirm(
        this.$t("sectionsPagePopupMessage"),
        this.$t("DistributerProfilePageDeleteAreaWarningBoxTitle"),
        {
          confirmButtonText: this.$t("sectionsPagePopupOkButtonLabel"),
          cancelButtonText: this.$t("sectionsPagePopupCancelButtonLabel"),
          type: "warning",
        }
      ).then(async () => {
        try {
          await providersService.assginProvidersTosections({
            sectionKey: sectionKey,
            regions: [Regionid],
            providers:
              this.providersList.filter((item) => item.updated).length > 0
                ? this.providersList
                    .filter((item) => item.updated)
                    .map((item) => {
                      return {
                        distributerId: item.id,
                        changeValue: item.checked,
                        minOrderAmount: item.minOrderAmount,
                        rating: item.rating,
                        minNumberItems: item.minNumberItems,
                      };
                    })
                : undefined,
          });
          this.getProviders();
        } catch (err) {
          this.getProviders();

          console.log("*************************************");
          console.log(err);
        }
      });
    },
    async getProviders() {
      try {
        this.$loading();
        const Regionid = this.selectedRegion.id;
        const providersResponse = await providersService.getProviderlookup({
          regions: Regionid ? [Regionid] : undefined,
        });
        this.providersList = providersResponse.map((p) => {
          p.checked = false;
          p.updated = false;
          return p;
        });
        this.getProvidersSecion();
      } catch (err) {
        console.log("*************************************");
        console.log(err);
        this.$loading().close();
      }
    },
    async getProvidersSecion() {
      try {
        const sectionKey = this.selectedSection.key;
        const Regionid = this.selectedRegion.id;
        const providersResponse = await providersService.getProviderlookup({
          provider: sectionKey ? sectionKey : "",
          regions: Regionid ? [Regionid] : undefined,
        });
        // CHECK THE EXISTENCE OF THIS PROVIDER ON SECTION
        this.providersList = this.providersList.map((p) => {
          const exist = providersResponse.some((provider) => {
            return provider.id == p.id;
          });
          if (exist) {
            p.checked = true;
          }
          return p;
        });
        this.providersData = _.cloneDeep(this.providersList);
      } catch (err) {
        console.log("*************************************");
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    downloadCSV(csvStr, name) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvStr);
      hiddenElement.target = "_blank";
      hiddenElement.download = `${name}.csv`;
      hiddenElement.click();
    },
    async getRegions() {
      try {
        // TODO REMOVE STATIC FIX FROM HERE
        let regionsResponse = await regionsService.getRegions();
        this.regionsList = regionsResponse.data.result.map((r) => {
          if (r.config == undefined) r.config = {};

          r.childs.map((smallR) => {
            if (smallR.config == undefined) smallR.config = {};
          });
          return r;
        });
        return regionsResponse;
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
        return err;
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    filterSectionNode(value, data) {
      console.log(data.title);
      console.log(value);
      if (!value) return true;
      return data.title.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },

    reigonClicked(node) {
      this.selectedRegion = node;
      this.providerFilter.regionId = node.id;
      this.getProviders();
      //   this.getAreas();
      //TODO Region Clicked
    },
    sectionClicked(node) {
      this.selectedSection = node;
      this.getProviders();
      console.log(node);
    },

    filterChanged() {
      //   this.getAreas();
    },
  },
};
</script>

<style lang="scss" scoped>
.area-title-container {
  display: flex;
  .el-input {
    width: 400px;
  }
}
.header {
  display: flex;
  justify-content: space-between;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  // padding-right: 8px;
  padding: 0 4px;
}
.selected {
  color: #fccd13;
}
.msg-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
}
.sections-page__float-button {
  float: right;
}
[dir="rtl"] {
  .bulk-action-button-holder {
    left: 5vw;
    right: auto;
  }
}
</style>
